import React, { useState } from 'react'
import { graphql, Link } from 'gatsby'
import { StaticImage, GatsbyImage } from 'gatsby-plugin-image' 
import styled from "styled-components"
import { BLOCKS, MARKS } from "@contentful/rich-text-types"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { ParallaxProvider } from 'react-scroll-parallax';
import { Parallax } from 'react-scroll-parallax';
import { useWindowSize } from "../components/general/window";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import Modal from "../components/general/modal"
import Layout from "../components/layout/layout"
import Head from '../components/general/head'
import { PageContainer, ContentWrapper, DeepBackground, HeadingWrapper, Title, Line, SolidBackground, TextureBackground, Container, BumperOffset, Bumper } from '../components/general/general'
import "../styles/templates/storyTemplateModal.css"
import { deviceMax } from "../styles/media"
import { palette } from "../styles/variables.js"

import "@fontsource/bebas-neue"
import "@fontsource/raleway/900.css"
import "@fontsource/raleway/800.css"
import "@fontsource/raleway/700.css"
import "@fontsource/raleway/600.css"
import "@fontsource/raleway/500.css"
import "@fontsource/raleway/400.css"
import "@fontsource/alegreya-sans/500.css"
import "@fontsource/alegreya-sans/400.css"
import "@fontsource/alegreya-sans/300.css"

import { HelpButton } from "../components/general/helpButton.js"

const HeaderWrapper = styled(props => <GatsbyImage {...props} />)`
    filter: drop-shadow(0px 20px 50px rgba(0,0,0,1));
    @media ${deviceMax.tablet} { 
        filter: drop-shadow(0px 10px 25px rgba(0,0,0,1));
    }
    @media ${deviceMax.mobileL} { 
        width: 200%;
        left: -50%;
        display: flex;
        justify-content: center;
        align-items: center;
        filter: drop-shadow(0px 0px 0px rgba(0,0,0,1));
    }
`;

const NavPanel = styled.div`
    position: relative;
    width: 100%;
    height: 8rem;
    padding: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 5;
    filter: drop-shadow(0px 10px 10px rgba(0,0,0,.6));
    @media ${deviceMax.tablet} { 
        flex-direction: column;
        height: 12rem;
        margin: 2rem 0;
    }
`;

const Button = styled(props => <Link {...props} />)`
        width: 15rem;
        height: 3rem;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: .7rem 1.1rem;
        margin: 0rem;
        z-index: 10;
        color: #FFF;
        background-color: #0F132E;
        border: 1px solid #4E7187;
        border-radius: 2rem;
        font-family: "Raleway";
        font-size: .8rem;
        font-weight: 500;
        letter-spacing: .06rem;
        text-decoration: none;
        // font-size: 2rem;
        transform: scale(1);
        
        text-transform: uppercase;

        cursor: pointer;
        transition: .2s ease-out;
    
        &:hover {
            transition: .1s ease-in;
            transform: scale(1.02);
            border-color: #b9d2e2;
            box-shadow: 0px 1px 5px #000;           
        }
        @media ${deviceMax.laptop} {
            width: 12rem;
            font-size: .6rem;
        }
        @media ${deviceMax.tablet} {
            width: 90%;
        }
    
`;
const ButtonModal = styled.button`
        width: 15rem;
        height: 3rem;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: .7rem 1.1rem;
        margin: 0rem;
        z-index: 10;
        color: #FFF;
        background-color: #0F132E;
        border: 1px solid #4E7187;
        border-radius: 2rem;
        font-family: "Raleway";
        font-size: .8rem;
        font-weight: 500;
        letter-spacing: .06rem;
        text-decoration: none;
        // font-size: 2rem;
        transform: scale(1);
        
        text-transform: uppercase;

        cursor: pointer;
        transition: .2s ease-out;
    
        &:hover {
            transition: .1s ease-in;
            transform: scale(1.02);
            border-color: #b9d2e2;
            box-shadow: 0px 1px 5px #000;           
        }
        @media ${deviceMax.laptop} {
            width: 12rem;
            font-size: .6rem;
        }
        @media ${deviceMax.tablet} {
            width: 90%;
        }    
`;
const Copy = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    align-content: flex-start;
    z-index: 1;
    @media ${deviceMax.laptop} { 
        /* width: 90%; */
        
    }
    @media ${deviceMax.tablet} { 
        flex-direction: column-reverse;
        
    }
    
`;
const Story = styled.div`
    width: 70%;
    height: 100%;
    background: #1F2239;
    border-radius: 5px;
    padding: 1.5rem;
    padding-top: 2rem;
    filter: drop-shadow(0px 20px 50px rgba(0,0,0,1));
    @media ${deviceMax.laptop} { 
        width: 100%;
        margin-right: 1rem;     
        filter: drop-shadow(0px 5px 10px rgba(0,0,0,1));
    }
    @media ${deviceMax.tablet} { 
        margin-right: 0;            
    }
    @media ${deviceMax.mobileL} { 
        width: 100%;
        margin-bottom: 2rem;   
        filter: drop-shadow(0px 0px 0px rgba(0,0,0,1));     
    }
`;
const Heading2 = styled.p`
    font-family: "Raleway";
    font-size: 2.2rem;
    line-height: 2.2rem;
    font-weight: 800;
    color: ${palette.text90};
    @media ${deviceMax.mobileM} { 
        font-size: 1.4rem;
        line-height: 1.4rem;     
    }
    @media ${deviceMax.mobileS} { 
        font-size: 1.2rem;
        line-height: 1.4rem;     
    }
`;
const Heading3 = styled.p`
    font-family: "Raleway";
    font-size: .9rem;
    font-weight: 600;
    padding-bottom: 1rem;
    color: ${palette.text90};
`;
const StyledText = styled.p`
    font-family: "Alegreya Sans";
    font-size: 1.1rem;
    text-align: justify;
    text-justify: inter-word;
    color: ${palette.text90};
    @media ${deviceMax.laptop} { 
        font-size: 1rem;
        line-height: 1.2rem;     
    }
    @media ${deviceMax.mobileL} { 
        font-size: 1rem;
        line-height: 1.2rem;     
    }
`;
const EntryColumn = styled.div`
    /* position: relative; */
    width: 15rem;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    z-index: 1;
    @media ${deviceMax.laptop} {
        width: auto;
    } 
    @media ${deviceMax.tablet} { 
        width: 100%;
        background: ${props => props.background};
        border-radius: 5px;
        margin: 0 0 2rem;
        overflow-x: scroll;
        &:after {
            content: '';
            position: absolute;
            right: 1rem;
            width: 2rem;
            height: 12.8rem;
            /* padding-right: .7rem; */
            background: ${props => props.fade};
            border-radius: 0 5px 5px 0;
            z-index: 3;
        }
    }
    @media ${deviceMax.mobileL} { 
        width: 100%;
        background: ${props => props.background};
        /* height: 15rem; */
        /* border: 2px solid blue; */
        border-radius: 5px;
        margin: 0 0 2rem;
        overflow-x: scroll;
        &:after {
            content: '';
            position: absolute;
            right: 0;
            width: 2rem;
            height: 12.8rem;
            /* padding-right: .7rem; */
            background: ${props => props.fade};
            border-radius: 0 5px 5px 0;
            z-index: 3;
        }
    }
    
`;
const Drawing = styled(props => <GatsbyImage {...props} />)`
    /* position: relative; */
    /* top: -20rem; */
    margin-top: -20rem;
    left: 0;
    /* width: 15rem; */
    height: 25rem;
    z-index: 1;
    filter: drop-shadow(0px 10px 10px rgba(0,0,0,.6));
    /* height: auto; */
    @media ${deviceMax.tablet} { 
        display: none;
        
    }
`;
const Entries = styled.div`
    position: relative;
    top: 0;
    left: 0;
    width: 15rem;
    /* height: auto; */
    margin-top: -5rem;
    background: ${props => props.background};
    border-radius: 5px;
    padding: .7rem;
    z-index: 2;
    filter: drop-shadow(0px 20px 50px rgba(0,0,0,1));
    @media ${deviceMax.laptop} {
        width: 100%;
        filter: drop-shadow(0px 5px 10px rgba(0,0,0,1));
        /* padding: 0; */
    }
    @media ${deviceMax.tablet} { 
        display: flex;
        flex-direction: row;
        width: auto;
        /* overflow-x: scroll; */
        /* overflow-y: hidden; */
        margin: 0;
        background: none;
        filter: drop-shadow(0px 0px 0px rgba(0,0,0,1));
    }
`;
const EntryLink = styled(props => <AnchorLink {...props} />)`
    text-decoration: none;
`;
const Entry = styled.div`
    width: 100%;
    margin: 0 0 1.5rem;
    text-decoration: none;
    /* border: 2px solid red; */
    cursor: pointer;
    transition: .2s ease-in-out;
    
    &:hover {
        transform: scale(1.02);
        filter: drop-shadow(0px 20px 15px rgba(0,0,0,.3));
    }
    @media ${deviceMax.laptop} {
        width: 11rem;
    } 
    @media ${deviceMax.tablet} { 
        width: 15rem;
        margin: 0 1rem 0 0; 

        
        
    }
    
`;
const Thumbnail = styled(props => <GatsbyImage {...props} />)`
    border: 2px solid #FFF;
    border-radius: 5px;
    
`;
const EntryNumber = styled.h4`
    font-family: "Raleway";
    text-transform: uppercase;
    margin: .5rem 0;
    color: ${palette.text90};
    transition: .2s ease-in-out;
    &:after {
        content: '';
        width: 0;
        height: 1px;
        display: block;
        background: #FFF;
        opacity: 0;
        transition: 300ms;
    }
    ${Entry}:hover & {
        &:after {
            opacity: 1;
            width: 75%;
        }
    }
    @media ${deviceMax.laptop} { 
        margin: .5rem 0 .2rem;
    }
`;
const EntryTitle = styled.h5`
    font-family: "Raleway";  
    font-weight: 500;
    font-size: .8rem;
    margin: 0;
    color: ${palette.text90};
    transition: .2s ease-in-out;
    &:after {
        content: '';
        width: 0;
        height: 1px;
        display: block;
        background: #FFF;
        opacity: 0;
        transition: 300ms;
    }
    ${Entry}:hover & {
        &:after {
            opacity: 1;
            width: 100%;
        }
    }
`;

const Treatment = styled.div`
    width: 55rem;
    /* height: 100%; */
    padding: 2rem 1.5rem 2rem;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    background: #1F2239;
    /* margin: 0 1rem; */
    /* border: 2px solid red; */
    @media ${deviceMax.laptop} { 
        width: 100%;
        /* margin: 0 2rem; */
        padding: 1rem .5rem 1rem;
    }
    @media ${deviceMax.mobileL} { 
        /* width: 100%; */
        /* margin: 0 2rem; */
        padding: 1rem .5rem 1rem;
    }
    @media ${deviceMax.mobileS} { 
        /* width: 100%; */
        /* margin: 0 2rem; */
        padding: 1rem 1rem 1rem;
    }
`;
const Lower = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;

    @media ${deviceMax.tablet} { 
        flex-direction: column;
    }
`;
const CopyWrap = styled.div`

    display: flex;
    flex-direction: column;
    /* align-items: center; */
    justify-content: center;
    padding-left: 1rem;
    /* border: 2px solid yellow; */
    @media ${deviceMax.mobileS} { 
        padding-left: 0;
    }
`;
const ImageWrap = styled.div`

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-left: 1rem;
    /* border: 2px solid yellow; */
    @media ${deviceMax.mobileL} { 
        display: none;
    }
`;
const TextWrap = styled.div`

    /* border: 2px solid red; */
    padding-right: 1rem;
    @media ${deviceMax.mobileS} { 
        padding-right: 0;
    }
    

`;
const TreatmentCopy = styled(StyledText)`
    @media ${deviceMax.tablet} { 
        font-size: 1rem;
        line-height: 1.2rem;
    }
    @media ${deviceMax.mobileL} { 
        font-size: 1rem;
        line-height: 1.2rem;
    }
    @media ${deviceMax.mobileS} { 
        font-size: .8rem;
        line-height: 1rem;     
    }
`;
const DrawWrap = styled.div`
    @media ${deviceMax.tablet} { 
        /* width: 70%; */
        height: 8rem;
        margin-bottom: 1rem;
        /* border: 2px solid yellow; */
        /* justify-content: flex-end; */
        /* margin-right: -2rem; */
        overflow: hidden;
        /* overflow-y: visible; */
        
    }
`;

const TherapistDrawing = styled(props => <GatsbyImage {...props} />)`
    width: 15rem;
    margin-bottom: 1rem;
    /* max-height: 14rem; */

    /* margin-top: 10rem; */
    filter: drop-shadow(0px 10px 10px rgba(0,0,0,.6));
    /* border: 2px solid cyan; */
    /* height: auto; */
    /* float: right; */
    /* bottom: -5rem; */
    @media ${deviceMax.tablet} { 
        width: 30%;
        border-bottom: 2px solid red;
        height: 2rem;
    }
    @media ${deviceMax.mobileL} { 
        display: none;
    }

`;
const CaptionText = styled.p`
    font-family: "Raleway";
    font-size: .8rem;
    text-align: center;
    text-justify: inter-word;
    color: ${palette.text90};
    margin: 0 0 1rem;
`;



const StoryTemplate = (props) => {

    let window = useWindowSize()
    const width  = window[0]

    const [showModal, setShowModal] = useState(false)

    const storyRichText = props.data.contentfulCharacter.characterStory.characterStory
    

    const Bold = ({ children }) => <span>{children}</span>
    const Text = ({ children }) => <p>{children}</p>

    const options = {
    renderMark: {
        [MARKS.BOLD]: text => <Bold>{text}</Bold>,
    },
    renderNode: {
        [BLOCKS.HEADING_2]: (node, children) => <Text><Heading2>{children}</Heading2></Text>,
        [BLOCKS.HEADING_3]: (node, children) => <Text><Heading3>{children}</Heading3></Text>,
        [BLOCKS.PARAGRAPH]: (node, children) => <Text><StyledText>{children}</StyledText></Text>,

        [BLOCKS.EMBEDDED_ASSET]: node => {
        return (
            <>
            <h2>Embedded Asset</h2>
            <pre>
                <code>{JSON.stringify(node, null, 2)}</code>
            </pre>
            </>
        )
        },
    },
    }

    return (
        
        <Layout>
            <Head title={`${props.data.contentfulCharacter.characterNamePossessive} Story`}/>
            <Modal showModal={showModal} setShowModal={setShowModal}>
                <Treatment>
                    
                    <Lower>
                        <CopyWrap>
                            <Heading2>
                                {props.data.contentfulCharacter.characterTreatment.title}
                            </Heading2>
                            <TextWrap>
                                <TreatmentCopy>{props.data.contentfulCharacter.characterTreatment.body.body}</TreatmentCopy>
                            </TextWrap>
                        </CopyWrap>
                        <ImageWrap>
                            <DrawWrap>
                                <TherapistDrawing
                                    image={props.data.contentfulCharacter.characterAssets.characterTherapist.gatsbyImageData}
                                    alt={props.data.contentfulCharacter.characterAssets.characterTherapist.gatsbyImageData}
                                />
                            </DrawWrap>
                            <CaptionText>
                                {props.data.contentfulCharacter.characterTherapist} <br/>
                                {props.data.contentfulCharacter.characterNamePossessive} Therapist
                            </CaptionText>
                            {/* <ThemeProvider theme={show}>
                                <HelpButton />
                            </ThemeProvider> */}
                        </ImageWrap>
                    </Lower>
                </Treatment>
            </Modal>
            <PageContainer>
                <ContentWrapper>
                <ParallaxProvider>
                    <Parallax
                        y={[-50, 50]}
                        slowerScrollRate
                        disabled={width <= 1024 }
                    >
                        <DeepBackground />
                    </Parallax>
                    
                    <Parallax
                        y={[100, 0]}
                        fasterScrollRate
                        disabled={width <= 1024 }
                    >
                    <HeadingWrapper>
                        <Title color={props.data.contentfulCharacter.characterColors.highlight}>{props.data.contentfulCharacter.characterName}</Title>
                        <Line>
                            <StaticImage
                                src="../images/lines/two-lines.png"
                                alt="Two Curved Light Weight Line"
                                layout="constrained"
                            />
                        </Line>
                    </HeadingWrapper> 
                    </Parallax>
                    <Parallax disabled={width <= 1024 }>
                        <SolidBackground>
                            <TextureBackground>
                                <Bumper>
                                    <HelpButton />
                                </Bumper>

                                <BumperOffset>
                                    <Container>
                                        <HeaderWrapper
                                            image={props.data.contentfulCharacter.characterAssets.characterHeader.gatsbyImageData}
                                            alt={props.data.contentfulCharacter.characterAssets.characterHeader.description}
                                        />
                                        <NavPanel>
                                            <ButtonModal onClick={setShowModal}>{props.data.contentfulCharacter.characterName} in Treatment</ButtonModal>
                                    
                                            <Button to={`/characters/${props.data.contentfulCharacter.slug}/insights`}>Therapist Insights</Button>
                                            <Button to={`/characters/${props.data.contentfulCharacter.slug}/entries`}>{props.data.contentfulCharacter.characterNamePossessive} Entries</Button>
                                        </NavPanel>
                                        <Copy>
                                            <Story>
                                                {renderRichText(storyRichText, options)}
                                            </Story>
                                            <EntryColumn background={props.data.contentfulCharacter.characterColors.primary} fade={`linear-gradient( 90deg, rgba(0,0,0,0), ${props.data.contentfulCharacter.characterColors.primary})`}>
                                                <Drawing
                                                    image={props.data.contentfulCharacter.characterAssets.illustratedColor.gatsbyImageData}
                                                    alt={props.data.contentfulCharacter.characterAssets.illustratedColor.description}
                                                />
                                                <Entries background={props.data.contentfulCharacter.characterColors.primary}>
                                                    {props.data.contentfulCharacter.characterEntries.entries.map((entry) => {
                                                        return (
                                                            <EntryLink to={`/characters/${props.data.contentfulCharacter.slug}/entries#${entry.entryId}`}>
                                                                <Entry>
                                                                        <Thumbnail
                                                                            image={entry.entryThumbnail.gatsbyImageData}
                                                                            alt={entry.entryThumbnail.description}
                                                                        />
                                                                    <EntryNumber>{entry.entryNumberText}</EntryNumber>
                                                                    <EntryTitle>{entry.entryTitle}</EntryTitle>
                                                                </Entry>
                                                            </EntryLink>
                                                        )
                                                    })}
                                                </Entries>
                                            </EntryColumn>
                                        </Copy>
                                    </Container>
                                </BumperOffset>
                            </TextureBackground>
                        </SolidBackground>
                    </Parallax>
                </ParallaxProvider>
                </ContentWrapper>
            </PageContainer>
        </Layout>
    )
}

export const query = graphql`
    query($slug: String!) {
        contentfulCharacter(slug: {eq: $slug}) {
            slug
            characterName
            characterNamePossessive
            characterTherapist
            characterColors {
                primary
                highlight
            }
            characterAssets {
                characterHeader {
                    gatsbyImageData (
                        layout: FULL_WIDTH
                        placeholder: BLURRED
                        formats: [AUTO, WEBP]
                    )
                    description
                }
                illustratedColor {
                    gatsbyImageData (
                        layout: FULL_WIDTH
                        placeholder: BLURRED
                        formats: [AUTO, WEBP]
                    )
                    description
                }
                characterTherapist {
                    gatsbyImageData (
                        layout: FIXED
                        width: 210
                        formats: [AUTO, WEBP]
                        cropFocus: TOP
                    )
                    title
                    description
                }
            }
            characterStory {
                characterStory {
                    raw
                }
            }
            characterTreatment {
                title
                body {
                    body
                }
            }
            characterEntries {
                entries {
                    entryThumbnail {
                        gatsbyImageData (
                            layout: FULL_WIDTH
                            placeholder: BLURRED
                            formats: [AUTO, WEBP]
                        )
                        description
                    }
                    entryNumber
                    entryId
                    entryNumberText
                    entryTitle
                }
            }
        }
    }
`

export default StoryTemplate