import React from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import styled from "styled-components";
import { deviceMax } from "../../styles/media"



const Backdrop = styled(motion.div)`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.5);
    z-index: 21;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem;
    @media ${deviceMax.mobileL} { 
        padding: 1rem;
        margin-top: 3rem;
        
    }
`;
const ModalBox = styled(motion.div)`
    /* width: 20rem;
    height:40rem; */
    /* opacity: 1; */
    /* width: 100%; */
    /* border: 2px solid red;  */
    z-index: 22;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @media ${deviceMax.mobileL} { 
        
        margin: 0 0rem;
        
    }
`;

const Close = styled.div`
    position: absolute;
    right:0;
    top: 0;
    align-self: flex-end;
    /* float: right; */
    width: 2.5rem;
    height: 2.5rem;
    background-color: #0F132E;
    border: 1px solid #4E7187;
    border-radius: 50%;
    transform: translate(1.25rem, -1.25rem);
    cursor: pointer;
    transition: .2s ease-out;
    
    &:hover {
        transition: .1s ease-in;
        border-color: #b9d2e2;
        box-shadow: 0px 1px 5px #000;           
    }
    &:before {
        content: '';
        height: .15rem;
        width: 1.6rem;
        background: #FFF;
        transform-origin: center;
        position: absolute;
        top: 1.175rem;
        left: .4rem;
        transform: rotate(45deg);
        border-radius: 25%;
    }
    &:after {
        content: '';
        height: .15rem;
        width: 1.6rem;
        background: #FFF;
        transform-origin: center;
        position: absolute;
        top: 1.175rem;
        left: .4rem;
        transform: rotate(-45deg);
        border-radius: 50%;
    }
    @media ${deviceMax.laptop} { 
        
        transform: scale(.8) translate(1.25rem, -1.25rem);
        
    }
`;

const backdrop = {
    visible: { opacity: 1 },
    hidden: { opacity: 0 }
}

const modal = {
    hidden: {
        y: "-5rem",
        opacity: 0
    },
    visible: {
        y: 0,
        opacity: 1,
        transition: { delay: 0.1 }
    },
    exit: {
        opacity: 0
    },
}

const Modal = ({ showModal, setShowModal, ...props }) => {
    return (
        <AnimatePresence exitBeforeEnter>
            { showModal && (
                <Backdrop onClick={() => setShowModal(false)}
                    variants={backdrop}
                    initial="hidden"
                    animate="visible"
                    exit="hidden"
                    transition={{ease: "easeInOut", duration: .2}}
                >
                 <ModalBox onClick={e => {
                // do not close modal if anything inside modal content is clicked
                e.stopPropagation();
                }}
                    variants={modal}
                    initial="hidden"
                    animate="visible"
                    exit="exit"
                >
                     <Close onClick={() => setShowModal(false)} />
                     {props.children}
                 {/* HEllO is this thing on */}
                 </ModalBox>   
                </Backdrop>
            )}
        </AnimatePresence>
    )
}

export default Modal